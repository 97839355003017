<template>
    <div>
        <div class="page-title">形象诊断书
            <button class="btn" @click="createZ">新建诊断书</button>
        </div>

        <div>
            <at-input v-model="query.member_id" class="search-btn mb-4" highlight placeholder="输入用户ID搜索">
                <button class="btn" @click="getFirstList">搜索</button>
            </at-input>
        </div>

        <a-modal title="形象诊断书" width="800px" v-model="showPreviewModal" :footer="null">
            <div v-if="previewItem">
                <report-editor
                        :member="previewItem.member"
                        :detail="previewItem"
                        :report-detail="previewItem.report_detail"
                        :report="previewItem.report"
                ></report-editor>
            </div>
        </a-modal>

        <a-table
                class="stock-table"
                :columns="table.columns"
                :data-source="table.data"
                :loading="table.loading"
                :pagination="table.pagination"
                :rowKey="record => record.id || record.key"
                @change="tableChanged"
                bordered
        >

            <div slot="time" slot-scope="time">
                {{ formatTime(time) }}
            </div>

            <div slot-scope="avatar" slot="avatar">
                <img width="40px" :src="avatar" alt="">
            </div>

            <div slot-scope="member" slot="nickname">
                {{ member.mark_name || member.name }}
            </div>

            <div slot="op" slot-scope="val,item" class="btn-groups">
                <button @click="preview(item)" class="btn mr-2">编辑</button>
                <a target="_blank" :href="pdfUrl(item)" class="btn mr-2">下载PDF</a>

                <button @click="delResult(item)" class="btn">删除</button>
            </div>

        </a-table>

        <at-modal v-model="showReportSearchModal" title="搜索形象报告" @ok="confirmSearch">
            <div>
                <div class="remark-item">
                    <div class="remark-title">输入用户ID搜索</div>
                    <div>
                        <at-input v-model="reportSearchContent" class="full-with"></at-input>
                    </div>
                </div>
            </div>
        </at-modal>

        <at-modal v-model="showReportListModal" title="选择一份报告" @ok="confirmSelectReport">
            <div class="report-list">
                <template v-for="(photo,pk) in reportList">
                    <div :key="pk" class="report-table" @click="selectReport(photo)"
                         :class="{ selected:photo === selectedPhoto }">
                        <div class="report-img" :style="`background-image:url(${photo.photo})`">
                        </div>
                        <div>
                            <div>
                                <b>ID：</b>
                                <span>{{ photo.id }}</span>
                            </div>
                            <div>
                                <b>年龄：</b>
                                <span>{{ photo.age }}</span>
                            </div>
                            <div>
                                <b>身高：</b>
                                <span>{{ photo.height }}CM</span>
                            </div>
                            <div>
                                <b>体重：</b>
                                <span>{{ photo.weight }}kg</span>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </at-modal>

    </div>
</template>

<script>
import AtModal from "@/components/AtModal.vue";
import AtInput from "@/components/AtInput.vue";
import api from "@/repo/api";
import moment from "moment/moment";
import ReportEditor from "@/views/AST/Member/Com/ReportEditor";

export default {
    name: "Report",
    components: {ReportEditor, AtInput, AtModal},
    data() {
        return {
            showDetailModal: false,
            showReportSearchModal: false,
            showPreviewModal: false,
            showReportListModal: false,
            reportSearchContent: '',
            previewItem: null,
            repData: {
                photo_id: 97782,
                grs_sq: "",
                zt_fg: "",
                zt_sc: "",
                sc_tx: "",
                zr_pf: "",
                fx_ys: "",
                result: ""
            },
            reportList: [],
            selectedPhoto: null,
            table: {
                columns: [
                    {
                        title: '诊断书ID',
                        dataIndex: 'id',
                        align: 'center',
                    },
                    {
                        title: 'AI报告ID',
                        dataIndex: 'photo_id',
                        align: 'center',
                    },
                    {
                        title: '用户ID',
                        dataIndex: 'member.member_id',
                        align: 'center',
                    },
                    {
                        title: '用户头像',
                        dataIndex: 'member.avatar',
                        align: 'center',
                        scopedSlots: {customRender: 'avatar'},
                    },
                    {
                        title: '用户昵称',
                        dataIndex: 'member',
                        align: 'center',
                        scopedSlots: {customRender: 'nickname'},
                    },
                    {
                        title: '时间',
                        dataIndex: 'created_at',
                        align: 'center',
                        scopedSlots: {customRender: 'time'},
                    },
                    {
                        title: '操作',
                        dataIndex: 'op',
                        align: 'center',
                        scopedSlots: {customRender: 'op'},
                    }
                ],
                loading: false,
                data: [],
                pagination: {
                    defaultCurrent: 1,
                    current: 1,
                    defaultPageSize: 10,
                    total: 1,
                    pageSize: 20
                },
            },
            query: {
                page: 1,
                member_id: null,
            },
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        getFirstList() {
            this.query.page = 1;
            this.getList()
        },
        createZ() {
            this.reportList = []
            this.selectedPhoto = null
            this.showReportSearchModal = true
        },
        getList() {
            api.get('/ast-app/photo-results', this.query, (data) => {

                this.table.data = data.data.data
                this.table.pagination.current = data.data.current_page
                this.table.pagination.total = data.data.total
                this.table.pagination.pageSize = data.data.per_page

                this.table.loading = false

            });
        },
        pdfUrl(item) {
            return api.colUrl('/ast-pdf/pdf') + '?id=' + item.id + '&hash=' + item.hash;
        },
        preview(item) {
            this.$router.push('/ast/members/report/detail?id=' + item.id)
        },
        delResult(item) {
            this.$confirm({
                title: '确定要删除这个形象诊断书吗？',
                onOk: () => {

                    api.post('/ast-app/delete-photo-result', {
                        id: item.id
                    }, (data) => {
                        this.$message.data(data)

                        if (data.code === 0) this.getList()
                    })

                }
            })
        },
        tableChanged(pagination) {

            this.query.page = pagination.current

            this.getList()

        },
        formatTime(time) {
            return moment(time).format('yyyy-MM-DD HH:mm')
        },
        confirmSelectReport() {
            this.$router.push('/ast/members/report/detail?photo_id=' + this.selectedPhoto.id)
        },
        selectReport(photo) {
            this.selectedPhoto = photo
        },
        confirmSearch() {

            this.$loading.show()

            api.get('/ast-app/photos', {
                member_id: this.reportSearchContent
            }, (data) => {

                this.$loading.hide()

                if (data.code === 0) {

                    let report = data.data

                    if (!report.length) return this.$message.error('该用户没有检测报告');

                    this.reportList = data.data

                    this.showReportSearchModal = false

                    this.showReportListModal = true

                } else {
                    this.$message.data(data)
                }

            });
        }
    }
}
</script>

<style scoped lang="less">
.report-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.report-img {
    width: 120px;
    height: 200px;
    background-size: cover;
    background-position: center;

}

.report-table {
    margin-bottom: 14px;
    cursor: pointer;
    border: 2px solid transparent;
    padding: 6px;

    &.selected {
        border-color: orange;
    }
}

.page-title {
    .btn {
        float: right;
    }
}

.remark-title {
    margin-bottom: 16px;
}

.remark-item {
    margin-bottom: 24px;
}

.btn-groups {
    display: flex;
    align-items: center;
    justify-content: center;
}

.mr-2 {
    margin-right: 8px;
}

.search-btn {
    width: 500px;
}

</style>